import 'core-js/features/promise';
import index from './lib/statistics/index';
import sliderStatistics from './lib/statistics/sliderStatistics';
import eisSliderStatistics from './lib/statistics/utils/eisSliderStatistics';

document.addEventListener('DOMContentLoaded', () => {
  index();
});

document.addEventListener('statisticsSlider', function() {
  sliderStatistics();
});

document.addEventListener('eisSliderStatistics', function() {
  eisSliderStatistics();
});

