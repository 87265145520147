import {regions} from './utils/regions';
import {parameters, defaultYearMap, legendAttrs,getNameLargeNumber,getTitleLegendMap,getTitleLegendMapExplanation} from './utils/settings';

export default function() {
  new LoadMap();
}

var showing = 0, tempWindow = 0, showColumnEcon;

class LoadMap {
  constructor() {
    this.addParamsLegend();
    this.initParams();
    this.setDefaultSettings();
  }

  setDefaultSettings() {
    const calculateMethodDoc = document.getElementById('calculate-method-doc');
    let mapElem = document.querySelector('#map');

    $(mapElem).on('mousemove', mouseMove);
    let mapSize = getMapSize(),
      //viewBox 703 = min mapElem.clientWidth
      canvas = d3.select(mapElem).append('svg:svg').attr('viewBox', '0 0 831 530').append('svg:g'),
      elements;

    const mapSvg = $(mapElem).find('svg');
    resizeMap(mapSvg, mapSize);

    window.addEventListener('resize', function() {
      if(mapElem) { resizeMap(mapSvg, getMapSize()); }
    });

    var tooltipElem = $('#common-tab .popup');
    var popupTitle = $('#common-tab .popup-title');
    tooltipElem.css('top', 'auto');

    function hideTooltip() {
      $('#common-tab #mun-advice .text-cap-base').css({'opacity':'0.5'});
      $('#common-tab #reg-mun').css({'color':'#6F7C98'});

      if (showing === 0) {
        tooltipElem.css('display', 'none');
      }
    }

    function freezeTooltip(region) {
      if (showing === 0) {
        tempWindow = tooltipElem;
        $('#common-tab .dropdown-list__item[data-idregion=' + (region.id + '000000000000').substring(0, 11) + ']').click();
        tooltipElem = tempWindow;
      } else {
        closePopUp();
      }
    }

    function freezeLabelTooltip(regionId) {
      if (showing === 0 && !$.isEmptyObject(mapRegion)) {
        mapDialogLoader(regionId);
        popupTitle.text($('#map > svg > g > path#' + regionId).attr('name'));
        tooltipElem.css('display', 'block');
        tooltipElem.css('padding-top','18px');
        tooltipElem.css('top','20px');
        tooltipElem.addClass('position-absolute');
        tooltipElem.find('.btn-close').removeClass('d-none');
        $('#map').addClass('freezed');
        showing = 1;
      }
    }

    function showTooltip(region) {
      $('#common-tab #mun-advice .text-cap-base').css({'opacity':'1'});
      $('#common-tab #reg-mun').css({'color':'#909EBB'});

      var isMobile = false;
      try {
        isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent));
      } catch (e) {}

      if (showing === 0 && !isMobile && !$.isEmptyObject(mapRegion)) {
        mapDialogLoader(region.id);
        popupTitle.text(region.name);
        tooltipElem.css('display', 'block');
        tooltipElem.css('padding-top','18px');
      }
    }

    function mouseMove(e) {
      if(showing === 0) {
        if (tooltipElem) {
          setTooltipPosition();
        }

        function setTooltipPosition() {
          var tooltipWidth = tooltipElem[0].clientWidth;
          var tooltipHeight = tooltipElem[0].clientHeight;

          var positionX = e.clientX - Math.round(tooltipWidth / 2);
          var positionXR = e.clientX + Math.round(tooltipWidth / 2);
          var positionY = e.clientY - tooltipHeight;

          tooltipElem.css('right', 'auto');
          tooltipElem.css('left','auto');
          tooltipElem.css('top', 'auto');
          tooltipElem.css('bottom', 'auto');

          if(positionX >= 0) {
            tooltipElem.css('left', e.clientX - Math.round(tooltipWidth / 2));
          }else{
            tooltipElem.css('left', e.clientX);
          }

          if(positionXR >= window.innerWidth) {
            tooltipElem.css('right',window.innerWidth - e.clientX + 10);
            tooltipElem.css('left','auto');
          }

          if(positionY >= 0) {
            tooltipElem.css('bottom', (document.documentElement.clientHeight + 10) - (e.clientY));
          } else {
            tooltipElem.css('top', (e.clientY + 10));
          }

          tooltipElem.css('position', 'fixed');
        }
      }
    }

    elements = canvas.selectAll('path').data(regions)
      .enter()
      .append('svg:path')
      .attr('fill', function(region) {
        return region.fill;
      })
      .attr('d', function(region) {
        return region.path;
      })
      .attr('stroke', function(region) {
        return region.stroke !== null ? region.stroke : 'none';
      })
      .attr('id', function(region) {
        return region.id;
      })
      .attr('name', function(region) {
        return region.name;
      })
      .on('mouseover', showTooltip.bind(this))
      .on('mouseleave', hideTooltip)
      .on('click', freezeTooltip.bind(this));

    function getMapSize() {
      let minWidth = 703,
        width = mapElem && mapElem.clientWidth ? mapElem.clientWidth : minWidth;

      return {
        height: '530px',
        width: width + 'px',
        maxWidth: width + 'px',
        minWidth: minWidth + 'px'
      };
    }

    function resizeMap(canvas, mapSize) {
      canvas.attr('width', mapSize.width)
        .attr('height', mapSize.height)
        .attr('style', `width:${mapSize.width}; height:${mapSize.height}; max-width:${mapSize.maxWidth}; min-width:${mapSize.minWidth};`);
    }

    $('#common-tab .btn-close').on('click', function() {
      closePopUp();
    });

    function closePopUp() {
      if (sessionStorage.getItem('clickSwitcher') !== '1') {
        sessionStorage.setItem('opened', '0');
      }
      hideWindow();
      $('#common-tab *[data-idregion="all"]').click();
    }

    function hideWindow() {
      showing = 0;
      tooltipElem.css('display', 'none');
      $('#map').removeClass('freezed');
      tooltipElem.find('.btn-close').addClass('d-none');
      tooltipElem.css('top','');
      tooltipElem.removeClass('position-absolute');
    }

    var regionName = Cookies.get('selectElementName');
    if (!regionName) {
      $('.region_name').text('Не выбран');
    } else {
      $('.region_name').text(regionName);
    }

    $('#common-tab .close__icon').on('click', function(e) {
      $('#common-tab *[data-idregion="all"]').click();
      e.stopPropagation();
    });

    $('#common-tab .js-param:not(.stat-tab)').on('click', function() {
      sessionStorage.setItem('clickSwitcher', '1');
      hideWindow();
    });

    var tempFillRegion = '';
    //Выбор региона
    $(document).on('click', '#common-tab .dropdown-list_region > .dropdown-list__item' , function(e) {
      showTooltipOnClickOrSwitch(e);
    });

    function showTooltipOnClickOrSwitch(e) {
      if (sessionStorage.getItem('clickSwitcher') === '1') {
        return;
      }

      const regionIdFromStorage = sessionStorage.getItem('regionId');
      const regionId = sessionStorage.getItem('clickSwitcher') === '2'? regionIdFromStorage : String($(e.currentTarget).data('idregion')).substring(0, 2);
      if (regionId === null || regionId === 'null') {
        sessionStorage.setItem('clickSwitcher', '0');
        return;
      }

      $("#map > svg > g > path[fill = '#3116db']").attr('fill', tempFillRegion);
      tempFillRegion = $('#map > svg > g > path#' + regionId).attr('fill');
      $('#map > svg > g > path#' + regionId).attr('fill', '#3116db');
      showing = 0;
      freezeLabelTooltip(regionId);
      $('#common-tab .map > .popup').removeAttr('style');
      tooltipElem.css('padding-top','18px');
      tooltipElem.css('top','20px');

      if (regionId === 'al') {
        $('#region-dropdown span[data-region]').removeClass('fontBoldTextTd');
      }
      else {
        sessionStorage.setItem('regionId', regionId);
        $('#region-dropdown span[data-region]').addClass('fontBoldTextTd');
      }

      //Определяем с какой стороны карты показывать подсказку
      const rightRegions = [14, 3, 38, 75, 87, 49, 41, 27, 65, 25, 79, 28];
      if (!rightRegions.includes(Number(regionId))) {
        tooltipElem.css('right','-120px');
      }
      if($.isEmptyObject(mapRegion)) tooltipElem.css('display','none');

      sessionStorage.setItem('clickSwitcher', '0');

      if (regionId === 'al') {
        hideWindow();
      } else {
        sessionStorage.setItem('opened', '1');
      }

    }

    //Клик вне области выбора регионов на легенде
    $(document).mouseup(function(e) {
      if($(e.target).closest('.statistic').length) {}
    });

    //Нажатие фильтрова КАРТА
    $('#common-tab div[data-value="map-stats"]').on('click', function() {
      calculateMethodDoc.classList.remove('d-none');
      $('#common-tab .btn-close').click();
    });
    //Нажатие фильтрова Пузырьков
    $('#common-tab div[data-value="all-stats"]').on('click', function() {
      calculateMethodDoc.classList.add('d-none');
      $('#common-tab .btn-close').click();
    });
    //Нажатие фильтрова 44ФЗ
    $('#common-tab div[data-value="FZ44"]').on('click', function() {
      $('#common-tab div[data-value="common-contracts"] .btn-text').text('Контракты');
      $('#common-tab [data-value="common-economy"]').css('display', 'inline-block');
      showColumnEcon = true;
      $('#common-tab .btn-close').click();
    });
    //Нажатие фильтрова 223ФЗ
    $('#common-tab div[data-value="FZ223"]').on('click', function() {
      $('#common-tab div[data-value="common-contracts"] .btn-text').text('Договоры');
      $('#common-tab [data-value="common-economy"]').css('display', 'none');

      if($('#common-tab [data-value="common-economy"]').hasClass('active')) {
        $('#common-tab [data-value="common-planing"]').click();
      }
      showColumnEcon = false;
      $('#common-tab .btn-close').click();
    });

    //Обработка нажатия фильтров а разделе статистика для формирования запроса
    $('#common-tab .js-param').on('click', function(event) {
      if($('#grafics-map .popup').is(':visible')) {
        $('#grafics-map .popup .btn-close').trigger('click');
      }

      $(this).closest('.js-params').find('.active').removeClass('active');
      $(this).addClass('active');

      parameters['commonTab'][event.currentTarget.dataset.param] = event.currentTarget.dataset.value;
      if (parameters.commonTab.mode !== 'map-stats') {
        parameters.commonTab.year = null;
      } else if (parameters.commonTab.year === null) {
        parameters.commonTab.year = $('#common-tab .params-years__item_active').text();
      }

      const currentDate = new Date();
      const roundingQuarter = currentDate.getFullYear().toString() === parameters.commonTab.year && currentDate.getMonth() + 1 < 4 ? 1E6 : 1E9;
      parameters.common.nameLargeNumber = getNameLargeNumber(roundingQuarter);

      checkDateForLegend(this);

      window.quarter = parameters.commonTab.year === String(new Date().getFullYear()) && (new Date().getMonth() + 1 < 4);
      requestCommonTab();
    });

    function checkDateForLegend(e) {
      var currentDate;
      if (typeof $('#common-tab [data-value="FZ223"]').data('value') !== 'undefined') {
        currentDate = +$('#common-tab .params-years__item_active').text();
      } else {
        currentDate = +$(e).text();
      }

      if (currentDate > new Date().getFullYear()) {
        if (!$('#common-tab [data-value="common-planing"]').hasClass('active')) {
          parameters['commonTab']['tabType'] = 'common-planing';
          $('#common-tab [data-value="common-planing"]').closest('.js-params').find('.active').removeClass('active');
          $('#common-tab [data-value="common-planing"]').addClass('active');
        }
        setDisplayTab('none');
      } else {
        setDisplayTab('inline-block');
      }
    }

    function setDisplayTab(displayStatus) {
      $('#common-tab [data-value="common-economy"],#common-tab [data-value="common-purchases"],#common-tab [data-value="common-contracts"]').css('display', displayStatus);
      if($('#common-tab [data-value="FZ223"]').hasClass('active')) $('#common-tab [data-value="common-economy"]').css('display', 'none');
    }

    //Вызов для начитки регионов при запуске страницы
    requestCommonTab();

    function requestCommonTab() {
      $('#common-tab .spinner-block').removeClass('d-none');
      let url = `/${parameters.commonTab.mode}/${parameters.commonTab.tabType}.json`;
      $.ajax({
        url: contextPath + url,
        method: 'GET',
        data: {
          placeOfSearch: parameters.commonTab.placeOfSearch,
          year: parameters.commonTab.year
        },
        success: function(data) {
          if (parameters.commonTab.mode === 'map-stats') insertCard(data); else insertAllstats(data);
          $('#common-tab .spinner-block').addClass('d-none');
          sessionStorage.setItem('clickSwitcher', '2');
          if (sessionStorage.getItem('opened') === '1') {
            showTooltipOnClickOrSwitch(null);
          }
          sessionStorage.setItem('clickSwitcher', '0');
        },
        error: function(e) {
          console.warn(e);
          $('#common-tab .spinner-block').addClass('d-none');
        }
      });
    }

    //Вставка раздела пузырики
    function insertAllstats(data) {
      $('#grafics-map').css('display', 'none');
      $('#stats').css('display', 'block');

      var cardTmpl;
      $.get({
        url: '/epz/static/js/templates/analyticsStatisticsTemplates.htm',
        cache: true
      }).then(
        function(templates) {
          cardTmpl = $(templates).filter('#tpl-stat-card').html();
          var resultList = data.searchResult;
          $('#allstatsContent > div').remove();
          $('#common-tab .grafics-btn-wrap').removeAttr('aria-disabled');
          $('#common-tab .grafics-btn-wrap').removeAttr('style');
          tooltipElem.css('padding-top', '18px');
          $('#common-tab .grafics-btn-wrap').removeClass('slick-disabled');
          $('#common-tab .grafics-btn-wrap').removeClass('slick-arrow');
          $('#common-tab .grafics-slider').removeClass('slick-slider');
          $('#common-tab .grafics-slider').removeClass('slick-initialized');
          for (var i in resultList) {
            // let nameLargeNumber = getNameLargeNumber(resultList[i].volume);
            let year = resultList[i].year;
            let volume = resultList[i].volume ? (Math.round((resultList[i].volume / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0;
            let count = resultList[i].count ? (Math.round((resultList[i].count) * 100) / 100).toLocaleString('ru') : 0;
            let volumeLabel = resultList[i].volumeLabel;
            let countLabel = resultList[i].countLabel;
            let lastYear = resultList[i].lastYear;

            if (parameters.commonTab.tabType === 'common-economy') count = count + ' %';
            const tmplData = {
              year: year,
              volume: volume,
              volumeLabel: volumeLabel,
              count: count,
              countLabel: countLabel,
              lastYear: lastYear,
              faceValue: parameters.common.nameLargeNumber.labelFaceValue
            };
            $('#allstatsContent').append(Mustache.render(cardTmpl, tmplData));
          }
          // document.dispatchEvent(event);
          var customEvent = document.createEvent('HTMLEvents');
          customEvent.initEvent('statisticsSlider', true, true);
          document.dispatchEvent(customEvent);
        });
    }

    //Результат ответа для карты
    var mapRegion;

    //Разукраска карты
    function fillMapRegion(border1, border2, border3, border4) {
      $('#map > svg > g > path').each(function(index, element) {
        let regionId = (element.id + '000000000000').substring(0, 11);
        let region = mapRegion[regionId];
        if (region) {
          let criterion = parameters.commonTab.tabType === 'common-economy' ? region.currentIndicator.count : region.currentIndicator.volume;
          if (criterion === null) criterion = undefined;
          //решили что при наведении курсора на такой субъект будем отображать подсказку и в показателях выводить 0
          //Границы рассчитываются автоматически от минимального и максимального значения показателя" - минимальное 0, значит дефолтный цвет
          if (criterion === undefined) $('#map > svg > g > path#' + element.id).attr('fill', '#eef1f6'); else
          if (criterion < border1) $('#map > svg > g > path#' + element.id).attr('fill', '#eef1f6'); else
          if (criterion < border2 && criterion >= border1) $('#map > svg > g > path#' + element.id).attr('fill', '#bcc5d9'); else
          if (criterion < border3 && criterion >= border2) $('#map > svg > g > path#' + element.id).attr('fill', '#9aa9c6'); else
          if (criterion < border4 && criterion >= border3) $('#map > svg > g > path#' + element.id).attr('fill', '#798cb3'); else
          if (criterion >= border4) $('#map > svg > g > path#' + element.id).attr('fill', '#576FA0');

          // Закрашиваем регион выбранный в легенде карты
          $('#map > svg > g > path#' + String($('#common-tab .dropdown-list_region > .dropdown-list__item_selected').data('idregion')).substring(0, 2)).attr('fill', '#014da8');
        } else {
          $('#map > svg > g > path#' + element.id).attr('fill', '#eef1f6');
        }
      });
    }

    //Вставка раздела карты
    function insertCard(data) {
      $('#grafics-map').css('display', 'block');
      $('#stats').css('display', 'none');
      insertLegendCard(data.borderLegendDto);
      mapRegion = data.searchResult;
      if($.isEmptyObject(mapRegion)) {
        $('#map > svg > g > path').attr('fill', '#dfdfdf');
      }

    }

    //Вставка легенды карты
    function insertLegendCard(data) {
      if (parameters.commonTab.placeOfSearch === 'FZ44') {
        $('#common-tab #223fz-map-partitioning-budgets').hide();
        $('#common-tab #44fz-map-partitioning-budgets').show();
      } else {
        $('#common-tab #44fz-map-partitioning-budgets').hide();
        $('#common-tab #223fz-map-partitioning-budgets').show();
      }

      let roundingLegend = parameters.commonTab.tabType === 'common-economy' ? 1 :parameters.common.nameLargeNumber.rounding;
      let faceValueLegend = parameters.common.nameLargeNumber.labelFaceValue;

      let legendTmpl;
      let totalTmpl;
      $.get({
        url: '/epz/static/js/templates/analyticsStatisticsTemplates.htm',
        cache: true
      }).then(
        function(templates) {
          // - - - - - - - - - - - - - легенда карты - - - - - - - - - - - - - - -//
          legendTmpl = $(templates).filter('#map-legend-tmpl').html();
          let labelFaceValue = parameters.commonTab.tabType === 'common-economy' ? '%' : parameters.common.nameLargeNumber.labelFaceValue + ' ₽';
          let maxBorder = Math.round(data.maxBorder / parameters.common.nameLargeNumber.rounding);
          let offset = 0;
          if (parameters.commonTab.tabType === 'common-economy') {
            offset = 2;
          } else {
            offset = maxBorder / 5;
            if (offset > 10) {
              offset = Math.round(offset / 10) * 10;
            }
          }

          let border1 = offset;
          let border2 = border1 + offset;
          let border3 = border2 + offset;
          let border4 = border3 + offset;

          var tmplData = {
            border1: border1.toLocaleString('ru'),
            border2: border2.toLocaleString('ru'),
            border3: border3.toLocaleString('ru'),
            border4: border4.toLocaleString('ru'),
            labelFaceValue: labelFaceValue
          };
          $('#common-tab #params-stats > div').remove();
          $('#common-tab #params-stats').append(Mustache.render(legendTmpl, tmplData));

          // - - - - - - - - - - - - - заполнение цветами краты - - - - - - - - - - - - - - -//
          fillMapRegion(border1 * roundingLegend, border2 * roundingLegend, border3 * roundingLegend, border4 * roundingLegend);

          // - - - - - - - - - - - - - сумма по всем субъектам - - - - - - - - - - - - - - -//
          totalTmpl = $(templates).filter('#map-total-tmpl').html();

          let total = data.totalAbs ? (Math.round((data.totalAbs / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0;
          let totalRel = (data.totalRel).toLocaleString('ru');

          // - - - - - блок информации по показателям в разрезе бюджетов организаций для 44-ФЗ - - -- - - - -//
          let absFed = data.budgetSectionDto.absFed? (Math.round((data.budgetSectionDto.absFed / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0;
          let aRegmun = data.budgetSectionDto.aRegmun? (Math.round((data.budgetSectionDto.aRegmun / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0;
          let absOth = data.budgetSectionDto.absOth? (Math.round((data.budgetSectionDto.absOth / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0;
          let absFau = data.budgetSectionDto.absFau? (Math.round((data.budgetSectionDto.absFau / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0;
          let aFedPbs =  data.budgetSectionDto.aFedPbs? (Math.round((data.budgetSectionDto.aFedPbs / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0;
          let aRegmunPbs = data.budgetSectionDto.aRegmunPbs? (Math.round((data.budgetSectionDto.aRegmunPbs / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0;
          let fedFaceLegend, regMunFaceLegend, otherFaceLegend, faceLegend;
          if (parameters.commonTab.tabType === 'common-economy') {
            const econRelFed = data.budgetSectionDto.econRelFed? data.budgetSectionDto.econRelFed.toLocaleString('ru') : 0;
            const econRelRegmun = data.budgetSectionDto.econRelRegmun? data.budgetSectionDto.econRelRegmun.toLocaleString('ru') : 0;
            const econRelOth = data.budgetSectionDto.econRelOth? data.budgetSectionDto.econRelOth.toLocaleString('ru') : 0;

            total = `${total} ${faceValueLegend} ₽ <span class="title--secondary">(${totalRel}&#37;)</span>`;
            aFedPbs = `${aFedPbs} ${faceValueLegend} ₽`;
            aRegmunPbs = `${aRegmunPbs} ${faceValueLegend} ₽`;
            fedFaceLegend = ` ${faceValueLegend} ₽ <span class="title--secondary">(${econRelFed}&#37;)</span>`;
            regMunFaceLegend = ` ${faceValueLegend} ₽ <span class="title--secondary">(${econRelRegmun}&#37;)</span>`;
            otherFaceLegend = ` ${faceValueLegend} ₽ <span class="title--secondary">(${econRelOth}&#37;)</span>`;

          } else {
            total = `${total} ${faceValueLegend} ₽`;
            aFedPbs = `${aFedPbs} ${faceValueLegend} ₽`;
            aRegmunPbs = `${aRegmunPbs} ${faceValueLegend} ₽`;
            faceLegend = ` ${faceValueLegend} ₽`;
          }

          let totalLabel = data.totalLabel;
          let year = parameters.commonTab.year;
          let totalData = {
            total: total,
            totalLabel: totalLabel,
            year: year
          };

          $('#common-tab .total-content').html('').append(Mustache.render(totalTmpl, totalData));

          insertMapPartitioningBudgets(absFed, aRegmun, absOth, absFau, aFedPbs, aRegmunPbs, fedFaceLegend, regMunFaceLegend, otherFaceLegend, faceLegend);

          $('#common-tab .params-title').text(`${getTitleLegendMap()}`);

          $('#title-legend-map-explanation').html(`${getTitleLegendMapExplanation()}`);

        });
    }

    function insertMapPartitioningBudgets(absFed, aRegmun, absOth, absFau, aFedPbs, aRegmunPbs, fedFaceLegend, regMunFaceLegend, otherFaceLegend, faceLegend) {
      $('#common-tab .map-partitioning-budgets').removeClass('d-none');
      if (parameters.commonTab.placeOfSearch === 'FZ44') {
        $('#common-tab #fed-advice .text-cap-base').html(absFed);
        $('#common-tab #mun-advice .text-cap-base').html(aRegmun);
        $('#common-tab #other-advice .text-cap-base').html(absOth);
        if (parameters.commonTab.tabType === 'common-economy') {
          $('#common-tab #fed-faceLegend').html(fedFaceLegend);
          $('#common-tab #mun-faceLegend').html(regMunFaceLegend);
          $('#common-tab #other-faceLegend').html(otherFaceLegend);
        } else {
          $('#common-tab #fed-faceLegend').html(faceLegend);
          $('#common-tab #mun-faceLegend').html(faceLegend);
          $('#common-tab #other-faceLegend').html(faceLegend);
        }
        $('#common-tab #fed-advice .text-cap-base').css({'opacity':'0.5'});
        $('#common-tab #mun-advice .text-cap-base').css({'opacity':'0.5'});
        $('#common-tab #other-advice .text-cap-base').css({'opacity':'0.5'});

        const fedAdvice = '<span class="custom-tooltiptext">Из них органы государственной власти, казенные учреждения и иные ПБС: ' + aFedPbs + '</span>';
        const munAdvice = '<span class="custom-tooltiptext">Из них органы местного самоуправления, в том числе их территориальные органы, муниципальные казенные учреждения и иные ПБС: ' + aRegmunPbs + '</span>';
        $('#common-tab #fed-advice').attr('data-tooltip', fedAdvice);
        $('#common-tab #mun-advice').attr('data-tooltip', munAdvice);
      } else {
        $('#common-tab #223fz-map-partitioning-budgets .text-cap-base').html(absFau);
        $('#common-tab #fau-faceLegend').html(faceLegend);
      }
    }

    function getCountName() {
      let countName = '';
      if(parameters.commonTab.placeOfSearch === 'FZ44') {
        switch (true) {
          case (parameters.commonTab.tabType === 'common-planing'):
            countName = 'планов-графиков';
            break;
          case (parameters.commonTab.tabType === 'common-purchases'):
            countName = 'извещений';
            break;
          default:
            countName = 'контрактов';
            break;
        }
      }
      if(parameters.commonTab.placeOfSearch === 'FZ223') {
        switch (true) {
          case (parameters.commonTab.tabType === 'common-planing'):
            countName = 'планов закупки';
            break;
          case (parameters.commonTab.tabType === 'common-purchases'):
            countName = 'извещений';
            break;
          default:
            countName = 'договоров';
            break;
        }
      }

      return countName;
    }

    function mapDialogLoader(path) {
      tooltipElem.css('padding-top','18px');
      tooltipElem.css('padding-bottom','0');
      var mapTmpl;
      var regionId = (path + '000000000000').substring(0, 11);
      $.get({
        url: '/epz/static/js/templates/analyticsStatisticsTemplates.htm',
        cache: true
      }).then(
        function(templates) {
          mapTmpl = $(templates).filter('#tpl-stat-popup').html();
          try {
            var resultList = mapRegion[regionId];
          } catch (err) {
            console.warn(`Нет данных для ${regionId} региона.`);
            console.warn(err);
          }
          $('#modalMap').empty();

          if (resultList === undefined) {
            tooltipElem.css('padding-bottom','18px');
            return;
          }

          let currentIndicator = $.extend({}, resultList.currentIndicator);
          let firstIndicator = $.extend({}, resultList.firstIndicator);
          let secondIndicator = $.extend({}, resultList.secondIndicator);
          let smpIndicator = $.extend({}, resultList.smpIndicator);
          let thirdIndicator = $.extend({}, resultList.thirdIndicator);
          let topPurchaseNumber = resultList.topPurchase.volumeLabel;
          let topPurchaseVolume = resultList.topPurchase.volume ? resultList.topPurchase.volume.toLocaleString('ru') : 0;
          let topPurchaseUrl = resultList.topPurchase.url;
          let topContractNumber = resultList.topContract.volumeLabel;
          let topContractVolume = resultList.topContract.volume ? resultList.topContract.volume.toLocaleString('ru') : 0;
          let topContractUrl = resultList.topContract.url;
          const budgetSection = resultList.budgetSection;

          currentIndicator.volume = currentIndicator.volume? (Math.round((currentIndicator.volume / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0;
          currentIndicator.count = currentIndicator.count? (Math.round((currentIndicator.count) * 100) / 100).toLocaleString('ru') : 0;
          currentIndicator.compOrWoContrVolume = currentIndicator.compOrWoContrVolume? (Math.round((currentIndicator.compOrWoContrVolume / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0;
          currentIndicator.compOrWoContrCount = currentIndicator.compOrWoContrCount? (Math.round((currentIndicator.compOrWoContrCount) * 100) / 100).toLocaleString('ru') : 0;

          firstIndicator.volume = firstIndicator.volume? (Math.round((firstIndicator.volume / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0;
          firstIndicator.count = firstIndicator.count? firstIndicator.count.toLocaleString('ru') : 0;
          secondIndicator.volume = secondIndicator.volume? (Math.round((secondIndicator.volume / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0;
          secondIndicator.count = secondIndicator.count? secondIndicator.count.toLocaleString('ru') : 0;
          thirdIndicator.volume = thirdIndicator.volume ? (Math.round((thirdIndicator.volume / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0;
          thirdIndicator.count = thirdIndicator.count? thirdIndicator.count.toLocaleString('ru') : 0;
          smpIndicator.volume = smpIndicator.volume? smpIndicator.volume.toLocaleString('ru') : 0;

          let currentIndicatorVFlag = currentIndicator.volume !== null;
          let currentIndicatorCFlag = currentIndicator.count !== null;
          let firstFlag = (firstIndicator.volume !== null && firstIndicator.count !== null) && showColumnEcon;
          let secondFlag = secondIndicator.volume !== null && secondIndicator.count !== null;
          let thirdFlag = thirdIndicator.volume !== null && thirdIndicator.count !== null;
          let isNotEmptyOneIndicators = currentIndicatorVFlag || currentIndicatorCFlag;
          let isNotEmptyFirstSecondThird = firstFlag || secondFlag || thirdFlag;
          let isNotEmptyTopSmp = topPurchaseNumber || smpIndicator.count || topContractNumber;
          let popupFlag = isNotEmptyFirstSecondThird || isNotEmptyTopSmp || isNotEmptyOneIndicators;
          const isFz44 = parameters.commonTab.placeOfSearch === 'FZ44';

          if ($('#common-tab .statistic-btns [data-param="tabType"].active').data('value') === 'common-economy') {
            currentIndicator.count += '%';
          } else if (isFz44) {
            firstIndicator.count += '%';
          }

          var tmplData = {
            quarter: quarter,
            currentIndicator: currentIndicator,
            firstIndicator: firstIndicator,
            secondIndicator: secondIndicator,
            thirdIndicator: thirdIndicator,
            topPurchaseNumber: topPurchaseNumber,
            topPurchaseVolume: topPurchaseVolume,
            topPurchaseUrl: topPurchaseUrl,
            topContractNumber: topContractNumber,
            topContractVolume: topContractVolume,
            topContractUrl: topContractUrl,
            topContractText: parameters.commonTab.placeOfSearch === 'FZ44' ? 'контракт' : 'договор',
            smpIndicator: smpIndicator,
            notEconomy: parameters.commonTab.tabType !== 'common-economy',
            isLotsOrContractsAndFz44: parameters.commonTab.placeOfSearch === 'FZ44' && (parameters.commonTab.tabType === 'common-purchases'
              || parameters.commonTab.tabType === 'common-contracts'),
            firstFlag: firstFlag,
            secondFlag: secondFlag,
            thirdFlag: thirdFlag,
            popupFlag: popupFlag,
            currentIndicatorVFlag: currentIndicatorVFlag,
            currentIndicatorCFlag: currentIndicatorCFlag,
            isNotEmptyFirstSecondThird: isNotEmptyFirstSecondThird,
            isNotEmptyTopSmp: isNotEmptyTopSmp,
            isNotEmptyOneIndicators: isNotEmptyOneIndicators,
            nameLargeNumber: parameters.common.nameLargeNumber,
            isFz44: isFz44,
            regPrice: budgetSection.regPrice? (Math.round((budgetSection.regPrice / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0,
            regPbsPrice: budgetSection.regPbsPrice? (Math.round((budgetSection.regPbsPrice / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0,
            munPrice: budgetSection.munPrice? (Math.round((budgetSection.munPrice / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0,
            munPbsPrice: budgetSection.munPbsPrice? (Math.round((budgetSection.munPbsPrice / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0,
            regCnt: budgetSection.regCnt? budgetSection.regCnt.toLocaleString('ru') : 0,
            regPbsCnt: budgetSection.regPbsCnt? budgetSection.regPbsCnt.toLocaleString('ru') : 0,
            munCnt: budgetSection.munCnt? budgetSection.munCnt.toLocaleString('ru') : 0,
            munPbsCnt: budgetSection.munPbsCnt? budgetSection.munPbsCnt.toLocaleString('ru') : 0,
            fauCnt223: budgetSection.fauCnt223? budgetSection.fauCnt223.toLocaleString('ru') : 0,
            fauPrice223: budgetSection.fauPrice223? (Math.round((budgetSection.fauPrice223 / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0,
            fgupCnt223: budgetSection.fgupCnt223? budgetSection.fgupCnt223.toLocaleString('ru') : 0,
            fgupPrice223: budgetSection.fgupPrice223? (Math.round((budgetSection.fgupPrice223 / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0,
            rmauCnt223: budgetSection.rmauCnt223? budgetSection.rmauCnt223.toLocaleString('ru') : 0,
            rmauPrice223: budgetSection.rmauPrice223? (Math.round((budgetSection.rmauPrice223 / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0,
            rmgupCnt223: budgetSection.rmgupCnt223? budgetSection.rmgupCnt223.toLocaleString('ru') : 0,
            rmgupPrice223: budgetSection.rmgupPrice223? (Math.round((budgetSection.rmgupPrice223 / parameters.common.nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') : 0,
            countName: getCountName()
          };
          $('#modalMap').append(Mustache.render(mapTmpl, tmplData));

          // сокрытие блоков при отсутствии данных
          hideModalMapEmptyBlocks(currentIndicator, tmplData, firstIndicator, secondIndicator, thirdIndicator, topPurchaseVolume,
            topContractVolume, smpIndicator, tooltipElem);

        });
    }

    function hideModalMapEmptyBlocks(currentIndicator, tmplData, firstIndicator, secondIndicator, thirdIndicator, topPurchaseVolume,
      topContractVolume, smpIndicator, tooltipElem) {
      // сокрытие блока "Информация по показателю"
      if (currentIndicator.volume === 0) {
        $('.popup-content#indicator-info').addClass('d-none');
      }
      //сокрытие блока "Информация по другим показателям"
      const isOtherIndicatorsEmpty = firstIndicator.volume === 0 && secondIndicator.volume === 0 && thirdIndicator.volume === 0;
      if (isOtherIndicatorsEmpty) {
        $('.popup-content#other-indicators-info').addClass('d-none');
      }
      //сокрытие блока "Самый крупный показатель"
      const isTopPurchaseIndicators = topPurchaseVolume === 0 && topContractVolume === 0 && smpIndicator.volume === 0;
      if (isTopPurchaseIndicators) {
        $('.popup-content#largest-indicator-info').addClass('d-none');
      }
      const isCommonBlocksEmpty = currentIndicator.volume === 0 && isOtherIndicatorsEmpty && isTopPurchaseIndicators;
      //сокрытие блока "Информация по показателю в разрезе уровней бюджета"
      if(parameters.commonTab.placeOfSearch === 'FZ44') {
        if (tmplData.regPrice === 0 && tmplData.munPrice === 0) {
          $('.popup-content#budget-levels-info').addClass('d-none');
          if (isCommonBlocksEmpty) {
            tooltipElem.css('padding-bottom','18px');
          }
        }
      }
      if(parameters.commonTab.placeOfSearch === 'FZ223') {
        if (tmplData.au223Price === 0 && tmplData.gup223Price === 0) {
          $('.popup-content#budget-levels-info').addClass('d-none');
          if (isCommonBlocksEmpty) {
            tooltipElem.css('padding-bottom','18px');
          }
        }
      }

    }

  }

  initParams() {
    showColumnEcon = true;
  }

  addParamsLegend() {
    $('#region-content').html('');
    $('#common-tab .region-dropdown').addClass('dropdown');
    let regions = legendAttrs.regions;
    for (let item in regions) {
      $('#common-tab #region-content').append(`<li class="dropdown-list__item" data-idregion="${regions[item].code}">${regions[item].name}</li>`);
    }
    $('#common-tab .dropdown-list_region').prepend('<li class="dropdown-list__item js-item-default dropdown-list__item_selected" data-idregion="all">Все</li>');

    for (let i = 2014; i <= legendAttrs.year + 2; i++) {
      $('#common-tab .year-dropdown .dropdown-list').append(`<li class="dropdown-list__item js-param dropdown-list__item_range" data-param="year" data-value="${i}">${i}</li>`);
    }
    $('#common-tab .year-dropdown').addClass('dropdown');
    $('#common-tab .year-dropdown .dropdown__text_selected').text(defaultYearMap);
    $(`#common-tab .year-dropdown [data-value='${defaultYearMap}']`).addClass('js-item-default dropdown-list__item_selected');

    document.dispatchEvent(new CustomEvent('overrideDropdownEvent', {
      detail: {
        selector: '#common-tab'
      }
    }));
  }
}
