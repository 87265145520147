export { btnFloatingMode };

function btnFloatingMode(param, element, event) {
  switch (param) {
    case 'checkBox':
      showBtnFloating(element, true);
      break;
    case 'select':
      if (!element.classList.contains('select-record-per-page--number') && !element.classList.contains('select-csv-setting')
        && !element.classList.contains('select-modal')) {
        showBtnFloating(element);
      }
      break;
    case 'datepicker':
    case 'inputDirOrText':
    case 'alphanumeric':
      showBtnFloating(element);
      break;
    case 'modal':
      doObserveSelectedElements(element, true, event);
      break;
    case 'deleteClassifiers':
    case 'modal-remove-processing':
      showBtnFloating(element, true, event);
      break;
    default:
      console.warn('Неизвестный тип элемента для btnFloating');
  }
}

function doObserveSelectedElements(element, addHeightInCalc, event) {
  const aim = document.getElementById($(element).attr('id')).parentNode.parentNode;
  const modalObserverConfig = {
    attributes: true,
    childList: true,
    subtree: true
  };
  const modalObserverCallBack = function(mutationsList, observer) {
    for (let mutation of mutationsList) {
      switch (mutation.type) {
        case 'childList':
        case 'subtree':
        case 'attributes':
          showBtnFloating(element, addHeightInCalc, event);
          break;
      }
    }
    observer.disconnect();
  };
  const modalObserver = new MutationObserver(modalObserverCallBack);
  if (aim) {
    modalObserver.observe(aim, modalObserverConfig);
  }
}

function showBtnFloating(element,addHeightInCalc, event) {
  const quickSearchParam = document.querySelector('.quick-search-param');
  const searchOptionsEditContainer = document.getElementById('searchOptionsEditContainer');
  if (quickSearchParam) {
    insertBtn('quick');
    const eventBoundingClientRect = document.body.contains(element) || !event ? element.getBoundingClientRect() : {left: event.clientX, top: event.clientY, height: 16};
    const btnFloating = document.getElementById('btn-floating');
    btnFloating.style.left = `${quickSearchParam.getBoundingClientRect().left - btnFloating.offsetWidth-10}px`;
    if(addHeightInCalc) {
      btnFloating.style.top = `${eventBoundingClientRect.top + window.pageYOffset-(eventBoundingClientRect.height*0.5)}px`;
    } else {
      btnFloating.style.top = `${eventBoundingClientRect.top + window.pageYOffset}px`;
    }
    btnFloating.style.opacity = '1';
    btnFloating.element = element;
  }
  if (searchOptionsEditContainer) {
    insertBtn('extended');
    const btnFloating = document.getElementById('btn-floating');
    let btnLeft;
    if (element) {
      const eventClosest = document.body.contains(element) ? element.closest('.search_tag, .js-btn-floating-c') : document.getElementById(element.dataset.parent+'Tag');
      if (eventClosest && eventClosest.getBoundingClientRect) {
        const eventBoundingClientRectST = eventClosest.getBoundingClientRect();
        btnFloating.style.top = `${eventBoundingClientRectST.top + window.pageYOffset - btnFloating.offsetHeight}px`;
        btnLeft = `${eventBoundingClientRectST.left + eventBoundingClientRectST.width * 0.5}px`;
      }
      if (document.body.contains(element) && element.getBoundingClientRect) {
        const eventBoundingClientRect = element.getBoundingClientRect();
        btnLeft = `${eventBoundingClientRect.left - btnFloating.offsetWidth*0.5 + eventBoundingClientRect.width*0.5}px`;
      }
      btnFloating.style.left = btnLeft;
    }
    btnFloating.style.opacity = '1';
    btnFloating.element = element;
  }
}

function insertBtn(placeToInsert) {
  let element = document.getElementById('btn-floating');
  if (element) {
    element.parentNode.removeChild(element);
  }
  if(placeToInsert === 'quick') {
    document.body.insertAdjacentHTML('beforeend','<div id="btn-floating" class="btn-floating--quick">\n' +
        '<button type="button" class="btn btn-primary" onclick="submitQuickSearchForm(); return false;">ПРИМЕНИТЬ</button>\n' +
        '</div>');
  } else {
    document.body.insertAdjacentHTML('beforeend','<div id="btn-floating" class="btn-floating--extended">\n' +
        '<button type="button" class="btn btn-primary" onclick="submitQuickSearchForm(); return false;">ПРИМЕНИТЬ</button>\n' +
        '<div class="btn-floating__arrow"></div>\n' +
    '</div>');
  }

}

window.addEventListener('resize', function() {
  const btn = document.getElementById('btn-floating');

  if (btn) {
    const quickSearchParam = document.querySelector('.quick-search-param');

    if (quickSearchParam) {
      btn.style.left = `${quickSearchParam.getBoundingClientRect().left - btn.offsetWidth-10}px`;
    } else {
      const searchOptionsEditContainer = document.getElementById('searchOptionsEditContainer');
      if (searchOptionsEditContainer) {
        const element = btn.element;

        if (element) {
          const eventClosest = document.body.contains(element)
            ? element.closest('.search_tag, .js-btn-floating-c')
            : document.getElementById(element.dataset.parent+'Tag');

          if (eventClosest && eventClosest.getBoundingClientRect) {
            const eventBoundingClientRect = eventClosest.getBoundingClientRect;
            btn.style.left = `${eventBoundingClientRect.left + eventBoundingClientRect.width * 0.5}px`;
          }

          if (document.body.contains(element) && element.getBoundingClientRect) {
            const eventBoundingClientRect = eventClosest.getBoundingClientRect;
            btn.style.left =
              `${eventBoundingClientRect.left - btn.offsetWidth*0.5 + eventBoundingClientRect.width*0.5}px`;
          }
        }
      }
    }
  }
});
