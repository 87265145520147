import {StatisticsServices} from '../services/statistics.services';
import {parameters, getNameLargeNumber, getTitleLegendPurchase, legendAttrs,getLabelDialogLoaderPurchase} from './utils/settings';

class purchaseTab {

  constructor() {
    this.statisticsServices = new StatisticsServices();
    this.tooltipElem = $('#purchase-tab .popup');
    this.priceLeft = document.querySelector('#purchase-donut-chart .js-price__left');
    this.labelLeft = document.querySelector('#purchase-donut-chart .js-label__left');
    this.priceRight = document.querySelector('#purchase-donut-chart .js-price__right');
    this.labelRight = document.querySelector('#purchase-donut-chart .js-label__right');
    this.popupTitle = document.querySelector('#purchase-donut-chart .popup-title');
    this.colorsPurchaseTab = [ '#A88CCC', '#ECB5E5', '#FE93B5', '#FFAE91', '#EED482', '#74DAE0'];
    this.labelDialogLoaderPurchase = getLabelDialogLoaderPurchase();
    this.addParamsLegend().then(() => {
      this.setDefaultSettings();
    });
  }

  setDefaultSettings() {
    const donutChart = document.querySelector('#purchase-donut-chart');
    $(donutChart).on('mousemove', mouseMove);
    this.tooltipElem.css('top', 'auto');
    const tooltipElem = this.tooltipElem;

    function mouseMove(e) {
      if (tooltipElem) {
        setTooltipPosition();
      }
      function setTooltipPosition() {
        var tooltipWidth = tooltipElem[0].clientWidth;
        var tooltipHeight = tooltipElem[0].clientHeight;

        var positionX = e.clientX - Math.round(tooltipWidth / 2);
        var positionXR = e.clientX + Math.round(tooltipWidth / 2);
        var positionY = e.clientY - tooltipHeight;

        tooltipElem.css('right', 'auto');
        tooltipElem.css('left','auto');
        tooltipElem.css('top', 'auto');
        tooltipElem.css('bottom', 'auto');

        if(positionX >= 0) {
          tooltipElem.css('left', e.clientX - Math.round(tooltipWidth / 2));
        }else{
          tooltipElem.css('left', e.clientX);
        }

        if(positionXR >= window.innerWidth) {
          tooltipElem.css('right',window.innerWidth - e.clientX + 10);
          tooltipElem.css('left','auto');
        }

        if(positionY >= 0) {
          tooltipElem.css('bottom', (document.documentElement.clientHeight + 10) - (e.clientY));
        } else {
          tooltipElem.css('top', (e.clientY + 10));
        }

        tooltipElem.css('position', 'fixed');
      }
    }

    //Обработка нажатия фильтров а разделе статистика для формирования запроса
    const purchaseTabJsPrams = document.querySelectorAll('#purchase-tab .js-param');
    Array.prototype.forEach.call(purchaseTabJsPrams, (el) => {
      el.addEventListener('click', this.setParameters.bind(this));
    });
    // document.querySelectorAll('#purchase-tab .js-param').addEventListener('click', this.setParameters.bind(this));
    this.requestPurchaseTab();
  }

  setParameters(e) {
    $(e.currentTarget).closest('.js-params').find('.active').removeClass('active');
    $(e.currentTarget).addClass('active');
    if(e.currentTarget.dataset.param === 'contractStatus') {
      if(parameters.purchaseTab.placeOfSearch === 'FZ44') {
        parameters.purchaseTab.contractStatus44FZ = e.currentTarget.dataset.value;
      } else {
        parameters.purchaseTab.contractStatus223FZ = e.currentTarget.dataset.value;
      }
    } else {
      parameters['purchaseTab'][e.currentTarget.dataset.param] = e.currentTarget.dataset.value;
    }
    if(e.currentTarget.dataset.param === 'placeOfSearch') {
      $('#purchase-tab .status-dropdown .dropdown-list__item_selected').removeClass('dropdown-list__item_selected');
      if(parameters.purchaseTab.placeOfSearch === 'FZ44') {
        let status44 = legendAttrs.statusList44;
        for (let i = 0; i < status44.length; i++) {
          $('#purchase-tab .status-dropdown .dropdown-list .dropdown-list__item')[i].innerText = status44[i].name;
        }
        $('#purchase-tab .status-dropdown [data-value="CONTRACT_EXPIRATION"]').addClass('d-none');
        $(`#purchase-tab .status-dropdown [data-value="${parameters.purchaseTab.contractStatus44FZ}"]`).addClass('dropdown-list__item_selected');
        $('#purchase-tab .statistic-btns [data-value="purchase-price"] .btn-text').text('Цена контрактов');
        $('#purchase-tab .statistic-btns [data-value="purchase-count"] .btn-text').text('Количество контрактов');
        $('#purchase-status-dropdown .js-dropdown__text--title').text('Статус контракта:');
        if (parameters.purchaseTab.contractStatus44FZ === 'ALL') {
          $('#purchase-tab .status-dropdown .js-dropdown-icon-arrow').css('display', 'inline-block');
          $('#purchase-tab .status-dropdown .close__icon').css('display', 'none');
        } else {
          $('#purchase-tab .status-dropdown .js-dropdown-icon-arrow').css('display', 'none');
          $('#purchase-tab .status-dropdown .close__icon').css('display', 'inline-block');
        }
      } else {
        let status223 = legendAttrs.statusList223;
        for (let i = 0; i < status223.length; i++) {
          $('#purchase-tab .status-dropdown .dropdown-list .dropdown-list__item')[i].innerText = status223[i].name;
        }
        $('#purchase-tab .status-dropdown [data-value="CONTRACT_EXPIRATION"]').removeClass('d-none');
        $(`#purchase-tab .status-dropdown [data-value="${parameters.purchaseTab.contractStatus223FZ}"]`).addClass('dropdown-list__item_selected');
        $('#purchase-tab .statistic-btns [data-value="purchase-price"] .btn-text').text('Цена договоров');
        $('#purchase-tab .statistic-btns [data-value="purchase-count"] .btn-text').text('Количество договоров');
        $('#purchase-status-dropdown .js-dropdown__text--title').text('Статус договора:');
        if (parameters.purchaseTab.contractStatus223FZ === 'ALL') {
          $('#purchase-tab .status-dropdown .js-dropdown-icon-arrow').css('display', 'inline-block');
          $('#purchase-tab .status-dropdown .close__icon').css('display', 'none');
        } else {
          $('#purchase-tab .status-dropdown .js-dropdown-icon-arrow').css('display', 'none');
          $('#purchase-tab .status-dropdown .close__icon').css('display', 'inline-block');
        }
      }
      $('#purchase-tab .status-dropdown .dropdown__text_selected').text($('#purchase-tab .status-dropdown .dropdown-list__item_selected').text());
    }
    $('#purchase-tab .params-title').text(`${getTitleLegendPurchase()}`);
    this.labelDialogLoaderPurchase = getLabelDialogLoaderPurchase();
    this.requestPurchaseTab();
  }

  hideTooltip() {
    this.tooltipElem.css('display', 'none');
  }

  showTooltip(data) {
    this.popupDialogLoader(data);
    this.tooltipElem.css('display', 'block');
  }

  popupDialogLoader(data) {
    this.popupTitle.textContent = data.data.name;
    this.tooltipElem.css('border-color', this.colorsPurchaseTab[data.index]);
    let count = 0;
    if(parameters.purchaseTab.tabType === 'purchase-price') {
      let nameLargeNumber = getNameLargeNumber(data.data.count);
      count = (Math.floor((data.data.count / nameLargeNumber.rounding) * 100) / 100).toLocaleString('ru') + ` ${nameLargeNumber.labelFaceValue} ₽`;
    } else {
      count = data.data.count.toLocaleString('ru');
    }
    this.priceLeft.textContent = count;
    this.labelLeft.textContent = this.labelDialogLoaderPurchase.labelLeft;
    this.priceRight.textContent = data.data.volume.econRel.toLocaleString('ru') + '%';
    this.labelRight.textContent = this.labelDialogLoaderPurchase.labelRight;
  }

  addTotal(dataTotal) {
    let total = 0;
    let faceValue = '';
    if(parameters.purchaseTab.tabType === 'purchase-price') {
      let nameLargeNumber = getNameLargeNumber(dataTotal.value);
      let rounding = nameLargeNumber.rounding;
      faceValue = nameLargeNumber.labelFaceValue;
      faceValue +=' ₽';
      total = dataTotal.value ? (Math.round((dataTotal.value / rounding) * 100) / 100).toLocaleString('ru') : 0;
    } else {
      total = dataTotal.value ? dataTotal.value.toLocaleString('ru') : 0;
    }
    $('#purchase-tab .total-content .title--no-data').remove();
    $('#purchase-tab .total-content .title').text(`${total} ${faceValue}`);
    $('#purchase-tab .total-content .sub-title').text(dataTotal.label);
  }

  addLegend(searchResult) {
    $('#purchase-tab .params-title').text(`${getTitleLegendPurchase()}`);
    $('#purchase-tab .params-stats').html('');
    const colorsPurchaseTab = this.colorsPurchaseTab;
    $.get({
      url: '/epz/static/js/templates/analyticsStatisticsTemplates.htm',
      cache: true
    }).then(
      function(templates) {
        let purchaseLegendTmpl = $(templates).filter('#arc-legend-tmpl').html();
        for (let i in searchResult) {
          let tmplData = {
            name: searchResult[i].name,
            color: colorsPurchaseTab[i]
          };
          $('#purchase-tab .params-stats').append(Mustache.render(purchaseLegendTmpl, tmplData));
        }
      });
  }

  requestPurchaseTab() {
    $('#purchase-tab .spinner-block').removeClass('d-none');
    document.querySelector('#purchase-donut-chart svg').remove();
    document.querySelector('#purchase-donut-chart div').insertAdjacentHTML('beforebegin', '<svg></svg>');
    this.statisticsServices.getPurchaseTabInfo().then((data) => {
      let searchResult = Object.keys(data.searchResult).map(e => data.searchResult[e]);
      searchResult.sort((a, b) => a.count > b.count ? -1 : 1);

      this.addTotal(data.totalDto);
      this.addLegend(searchResult);

      let total = data.totalDto.value;

      const width = 432,
        chartWidth = 432,
        chartHeight = 432,
        height = 432,
        radius = Math.min(chartWidth, chartHeight) / 2,
        innerRadius = 136;

      const formatter = d3.format('$,');

      const svg = d3.select('#purchase-donut-chart svg')
        .attr('width', width)
        .attr('height', height);

      const arc = d3.arc()
        .innerRadius(innerRadius)
        .outerRadius(radius);

      const pie = d3.pie()
        .padAngle(0.01)
        .value(d => {
          let value = d.count/total*100;
          if (value < 1 ) value = 1;
          return value;
        });

      const arcGroup = svg
        .append('g')
        .attr('transform', `translate(${chartWidth / 2},${chartHeight / 2})`)
        .attr('class', 'arc-group');

      arcGroup
        .selectAll('.arc')
        .data(pie(searchResult))
        .enter()
        .append('g')
        .attr('class', 'arc-group')
        .append('path')
        .attr('class', 'arc')
        // .attr('stroke', '#fff')
        .attr('d', arc)
        .attr('fill', (d, i) => this.colorsPurchaseTab[i])
        .on('mouseover', this.showTooltip.bind(this))
        .on('mouseleave', this.hideTooltip.bind(this));

      $('#purchase-tab .spinner-block').addClass('d-none');
    },(e) => {
      document.querySelector('#purchase-tab .params-stats').innerHTML = '';
      document.querySelector('#purchase-tab .total-content .title').textContent = '';
      $('#purchase-tab .total-content div.title--no-data').remove();
      document.querySelector('#purchase-tab .total-content').insertAdjacentHTML('beforeend', '<div class="title--no-data text-center">Нет данных для отображения. <br><br> Попробуйте изменить параметры фильтрации данных.</div>');
      document.querySelector('#purchase-tab .total-content .sub-title').textContent = '';
      document.querySelector('#purchase-tab .spinner-block').classList.add('d-none');
    });
  }

  addParamsLegend() {
    return new Promise((resolve, reject) => {
      $('#purchase-tab .region-dropdown .dropdown-list').html('');
      $('#purchase-tab .region-dropdown').addClass('dropdown');
      let regions = legendAttrs.regions;
      for (let item in regions) {
        $('#purchase-tab .region-dropdown .dropdown-list').append(`<li class="dropdown-list__item js-param" data-param="kladr" data-value="${regions[item].code}">${regions[item].name}</li>`);
      }
      $('#purchase-tab .region-dropdown .dropdown-list').prepend('<li class="dropdown-list__item js-param js-item-default dropdown-list__item_selected" data-param="kladr" data-value="00000000000">Все</li>');

      $('#purchase-tab .year-dropdown').addClass('dropdown');
      for (let i = 2014; i <= legendAttrs.year; i++) {
        $('#purchase-tab .year-dropdown .dropdown-list').append(`<li class="dropdown-list__item js-param dropdown-list__item_range" data-param="year" data-value="${i}">${i}</li>`);
      }
      $('#purchase-tab .year-dropdown .dropdown__text_selected').text(legendAttrs.year);
      $(`#purchase-tab .year-dropdown [data-value='${legendAttrs.year}']`).addClass('js-item-default dropdown-list__item_selected');

      $('#purchase-tab .status-dropdown').addClass('dropdown');
      let status = legendAttrs.statusList44;
      for (let item in status) {
        $('#purchase-tab .status-dropdown .dropdown-list').append(`<li class="dropdown-list__item js-param" data-param="contractStatus" data-value="${status[item].code}">${status[item].name}</li>`);
      }
      $('#purchase-tab .status-dropdown [data-value="CONTRACT_EXPIRATION"]').addClass('d-none');
      $('#purchase-tab .status-dropdown .dropdown__text_selected').text(status[0].name);
      $(`#purchase-tab .status-dropdown [data-value='${status[0].code}']`).addClass('js-item-default dropdown-list__item_selected');

      document.dispatchEvent(new CustomEvent('overrideDropdownEvent', {
        detail: {
          selector: '#purchase-tab'
        }
      }));
      resolve();
    });
  }
}

export default function() {
  new purchaseTab();
}
