import {parameters} from '../statistics/utils/settings';

export class StatisticsServices {
  constructor() {
  }

  getPurchaseTabInfo() {
    return new Promise((resolve, reject) => {
      let url = `/${parameters.purchaseTab.mode}/${parameters.purchaseTab.tabType}.json`;
      let contractStatus = parameters.purchaseTab.placeOfSearch === 'FZ44' ? parameters.purchaseTab.contractStatus44FZ : parameters.purchaseTab.contractStatus223FZ;
      $.ajax({
        url: contextPath + url,
        method: 'GET',
        data: {
          placeOfSearch: parameters.purchaseTab.placeOfSearch,
          year: parameters.purchaseTab.year,
          kladr: parameters.purchaseTab.kladr,
          contractStatus: contractStatus
        },
        success: function(data) {
          if (data.searchResult) {
            resolve(data);
          }else{
            reject();
          }
        },
        error: function(e) {
          console.warn(e);
          reject(e);
        }
      });
    });
  }

  getEtpTabInfo() {
    return new Promise((resolve, reject) => {
      let url = `/${parameters.etpTab.mode}/${parameters.etpTab.tabType}.json`;
      $.ajax({
        url: contextPath + url,
        method: 'GET',
        data: {
          year: parameters.etpTab.year,
        },
        success: function(data) {
          if (data.searchResult) {
            resolve(data);
          } else {
            reject();
          }
        },
        error: function(e) {
          console.warn(e);
          reject(e);
        }
      });
    });
  }

  getEisTabInfo() {
    return new Promise((resolve, reject) => {
      let url = `/${parameters.eisTab.mode}/${parameters.eisTab.tabType}.json`;
      $.ajax({
        url: contextPath + url,
        method: 'GET',
        success: function(data) {
          resolve(data);
        },
        error: function(e) {
          console.warn(e);
          $('#eis-tab .spinner-block').addClass('d-none');
          reject(e);
        }
      });
    });
  }

  getLegendAttrs() {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: contextPath + '/stat-utils/legend-attrs.json',
        method: 'GET',
        success: function(data) {
          resolve(data);
        },
        error: function(e) {
          console.warn(e);
        }
      });
    });
  }

}
