import {StatisticsServices} from '../services/statistics.services';
import {parameters, getNameLargeNumber, getTitleLegendEtp, legendAttrs} from './utils/settings';

class etpTab {

  constructor() {
    this.statisticsServices = new StatisticsServices();
    this.tooltipElem = $('#etp-tab .popup');
    this.priceLeft = document.querySelector('#etp-donut-chart .js-price__left');
    this.labelLeft = document.querySelector('#etp-donut-chart .js-label__left');
    this.priceRight = document.querySelector('#etp-donut-chart .js-price__right');
    this.labelRight = document.querySelector('#etp-donut-chart .js-label__right');
    this.popupTitle = document.querySelector('#etp-donut-chart .popup-title');
    this.colorsEtpTab = [ '#74DAE0', '#67B7DC', '#6788DC', '#7D60E7', '#A367DC', '#DC61D7','#DF94BC','#FAA9BF'];
    this.addParamsLegend().then(() => {
      this.setDefaultSettings();
    });
  }

  setDefaultSettings() {
    const donutChart = document.querySelector('#etp-donut-chart');
    $(donutChart).on('mousemove', mouseMove);
    this.tooltipElem.css('top', 'auto');
    const tooltipElem = this.tooltipElem;

    function mouseMove(e) {
      if (tooltipElem) {
        setTooltipPosition();
      }
      function setTooltipPosition() {
        var tooltipWidth = tooltipElem[0].clientWidth;
        var tooltipHeight = tooltipElem[0].clientHeight;

        var positionX = e.clientX - Math.round(tooltipWidth / 2);
        var positionXR = e.clientX + Math.round(tooltipWidth / 2);
        var positionY = e.clientY - tooltipHeight;

        tooltipElem.css('right', 'auto');
        tooltipElem.css('left','auto');
        tooltipElem.css('top', 'auto');
        tooltipElem.css('bottom', 'auto');

        if(positionX >= 0) {
          tooltipElem.css('left', e.clientX - Math.round(tooltipWidth / 2));
        }else{
          tooltipElem.css('left', e.clientX);
        }

        if(positionXR >= window.innerWidth) {
          tooltipElem.css('right',window.innerWidth - e.clientX + 10);
          tooltipElem.css('left','auto');
        }

        if(positionY >= 0) {
          tooltipElem.css('bottom', (document.documentElement.clientHeight + 10) - (e.clientY));
        } else {
          tooltipElem.css('top', (e.clientY + 10));
        }

        tooltipElem.css('position', 'fixed');
      }
    }

    //Обработка нажатия фильтров а разделе статистика для формирования запроса
    const purchaseTabJsPrams = document.querySelectorAll('#etp-tab .js-param');
    Array.prototype.forEach.call(purchaseTabJsPrams, (el) => {
      el.addEventListener('click', this.setParameters.bind(this));
    });
    // document.querySelectorAll('#purchase-tab .js-param').addEventListener('click', this.setParameters.bind(this));
    this.requestPurchaseTab();
  }

  setParameters(e) {
    $(e.currentTarget).closest('.js-params').find('.active').removeClass('active');
    $(e.currentTarget).addClass('active');
    parameters['etpTab'][e.currentTarget.dataset.param] = e.currentTarget.dataset.value;
    this.requestPurchaseTab();
  }

  hideTooltip() {
    this.tooltipElem.css('display', 'none');
  }

  showTooltip(data) {
    this.popupDialogLoader(data);
    this.tooltipElem.css('display', 'block');
  }

  popupDialogLoader(data) {
    this.popupTitle.textContent = data.data.name;
    this.tooltipElem.css('border-color', this.colorsEtpTab[data.index]);
    let count = 0;
    let nameLargeNumberCount = getNameLargeNumber(data.data.count);
    if(parameters.etpTab.tabType === 'etp-price') {
      count = (Math.floor((data.data.count / nameLargeNumberCount.rounding) * 100) / 100).toLocaleString('ru') + ` ${nameLargeNumberCount.labelFaceValue} ₽`;
      this.priceLeft.innerHTML = '';
      this.priceLeft.textContent = count;
      this.labelLeft.textContent = data.data.countLabel;
      this.priceRight.textContent = data.data.volume.econRel.toLocaleString('ru') + '%';
      this.labelRight.textContent = data.data.volumeLabel;
    } else {
      let nameLargeNumberEconAbs = getNameLargeNumber(data.data.volume.econAbs);
      count = (Math.floor((data.data.count / nameLargeNumberCount.rounding) * 100) / 100).toLocaleString('ru') + ` ${nameLargeNumberCount.labelFaceValue} ₽`;
      let econAbs = (Math.floor((data.data.volume.econAbs / nameLargeNumberEconAbs.rounding) * 100) / 100).toLocaleString('ru') + ` ${nameLargeNumberEconAbs.labelFaceValue} ₽`;
      this.priceLeft.innerHTML = `${data.data.volume.econRel.toLocaleString('ru')}% <span class="sub-price"> / ${econAbs}</span>`;
      this.labelLeft.textContent = data.data.volumeLabel;
      this.priceRight.textContent = count;
      this.labelRight.textContent = data.data.countLabel;
    }
  }

  addTotal(dataTotal) {
    let total = 0;
    let faceValue = '';

    let nameLargeNumber = getNameLargeNumber(dataTotal.value);
    let rounding = nameLargeNumber.rounding;
    faceValue = nameLargeNumber.labelFaceValue + ' ₽';
    total = dataTotal.value ? (Math.round((dataTotal.value / rounding) * 100) / 100).toLocaleString('ru') : 0;
    $('#etp-tab .total-content .title--no-data').remove();
    $('#etp-tab .total-content .title').text(`${total} ${faceValue}`);
    $('#etp-tab .total-content .sub-title').text(dataTotal.label);
  }

  addLegend(searchResult) {
    $('#etp-tab .params-title').text(`${getTitleLegendEtp()}`);
    $('#etp-tab .params-stats').html('');
    const colorsEtpTab = this.colorsEtpTab;
    $.get({
      url: '/epz/static/js/templates/analyticsStatisticsTemplates.htm',
      cache: true
    }).then(
      function(templates) {
        let purchaseLegendTmpl = $(templates).filter('#arc-legend-tmpl').html();
        for (let i in searchResult) {
          let tmplData = {
            name: searchResult[i].name,
            color: colorsEtpTab[i]
          };
          $('#etp-tab .params-stats').append(Mustache.render(purchaseLegendTmpl, tmplData));
        }
      });
  }

  requestPurchaseTab() {
    $('#etp-tab .spinner-block').removeClass('d-none');
    document.querySelector('#etp-donut-chart svg').remove();
    document.querySelector('#etp-donut-chart div').insertAdjacentHTML('beforebegin', '<svg></svg>');
    this.statisticsServices.getEtpTabInfo().then((data) => {
      let searchResult = Object.keys(data.searchResult).map(e => data.searchResult[e]);
      if(parameters.etpTab.tabType === 'etp-price') {
        searchResult.sort((a, b) => a.count > b.count ? -1 : 1);
      } else {
        searchResult.sort((a, b) => a.volume.econRel > b.volume.econRel ? -1 : 1);
      }

      this.addTotal(data.totalDto);
      this.addLegend(searchResult);
      let total = data.totalDto.value;
      const width = 432,
        chartWidth = 432,
        chartHeight = 432,
        height = 432,
        radius = Math.min(chartWidth, chartHeight) / 2,
        innerRadius = 136;

      const formatter = d3.format('$,');

      const svg = d3.select('#etp-donut-chart svg')
        .attr('width', width)
        .attr('height', height);

      const arc = d3.arc()
        .innerRadius(innerRadius)
        .outerRadius(radius);

      let pie = null;
      if(parameters.etpTab.tabType === 'etp-price') {
        pie = d3.pie()
          .padAngle(0.01)
          .value(d => {
            let value = d.count / total * 100;
            if (value < 1 ) value = 1;
            return value;
          });
      } else {
        pie = d3.pie()
          .padAngle(0.01)
          .value(d => {
            let value = d.volume.econRel;
            if (value < 1 ) value = 1;
            return value;
          });
      }

      const arcGroup = svg
        .append('g')
        .attr('transform', `translate(${chartWidth / 2},${chartHeight / 2})`)
        .attr('class', 'arc-group');

      arcGroup
        .selectAll('.arc')
        .data(pie(searchResult))
        .enter()
        .append('g')
        .attr('class', 'arc-group')
        .append('path')
        .attr('class', 'arc')
        // .attr('stroke', '#fff')
        .attr('d', arc)
        .attr('fill', (d, i) => this.colorsEtpTab[i])
        .on('mouseover', this.showTooltip.bind(this))
        .on('mouseleave', this.hideTooltip.bind(this));

      $('#etp-tab .spinner-block').addClass('d-none');
    },(e) => {
      document.querySelector('#etp-tab .params-stats').innerHTML = '';
      document.querySelector('#etp-tab .total-content .title').textContent = '';
      document.querySelector('#etp-tab .total-content').insertAdjacentHTML('beforeend', '<div class="title--no-data text-center">Нет данных для отображения. <br><br> Попробуйте изменить параметры фильтрации данных.</div>');
      document.querySelector('#etp-tab .total-content .sub-title').textContent = '';
      document.querySelector('#etp-tab .spinner-block').classList.add('d-none');
    });
  }

  addParamsLegend() {
    return new Promise((resolve, reject) => {
      $('#etp-tab .year-dropdown').addClass('dropdown');
      for (let i = 2014; i <= legendAttrs.year; i++) {
        $('#etp-tab .year-dropdown .dropdown-list').append(`<li class="dropdown-list__item js-param dropdown-list__item_range" data-param="year" data-value="${i}">${i}</li>`);
      }
      $('#etp-tab .year-dropdown .dropdown__text_selected').text(legendAttrs.year);
      $(`#etp-tab .year-dropdown [data-value='${legendAttrs.year}']`).addClass('js-item-default dropdown-list__item_selected');

      document.dispatchEvent(new CustomEvent('overrideDropdownEvent', {
        detail: {
          selector: '#etp-tab'
        }
      }));
      resolve();
    });
  }
}

export default function() {
  new etpTab();
}
