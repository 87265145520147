import {StatisticsServices} from '../services/statistics.services';
import {parameters} from './utils/settings';
import {iconForAllArr,iconForFzArr} from './utils/iconForEis';

class eisTab {

  constructor() {
    this.statisticsServices = new StatisticsServices();
    this.setDefaultSettings();
    this.intervalUpdateEisStats = null;
    this.timerUpdateEisStats = null;
    this.searchResult = null;
    this.updateDate = null;
    this.isUpdate = false;
  }

  setDefaultSettings() {
    //Обработка нажатия фильтров а разделе статистика для формирования запроса
    const purchaseTabJsPrams = document.querySelectorAll('#eis-tab .js-param');
    Array.prototype.forEach.call(purchaseTabJsPrams, (el) => {
      el.addEventListener('click', this.setParameters.bind(this));
    });
    this.requestPurchaseTab();
  }

  setParameters(e) {
    $(e.currentTarget).closest('.js-params').find('.active').removeClass('active');
    $(e.currentTarget).addClass('active');
    parameters['eisTab'][e.currentTarget.dataset.param] = e.currentTarget.dataset.value;
    this.requestPurchaseTab();
  }

  requestPurchaseTab() {
    $('#eis-tab .spinner-block').removeClass('d-none');
    this.statisticsServices.getEisTabInfo().then((data) => {
      this.searchResult = Object.keys(data.searchResult).map(e => data.searchResult[e]);
      this.updateDate = data.updateDateTime;
      let searchResult = this.searchResult;
      $('#eis-tab .js-grafics-btn').html('');
      let eisTabGrafics = $('#eis-tab .js-grafics');
      eisTabGrafics.removeClass().addClass('js-grafics row').html('');
      if (this.intervalUpdateEisStats) {
        clearInterval(this.intervalUpdateEisStats);
      }
      if (this.timerUpdateEisStats) {
        clearTimeout(this.timerUpdateEisStats);
      }
      this.addPeriod(data.updateDateTime);
      this.isUpdate = true;
      let thisReq = this;
      if (parameters.eisTab.tabType === 'eis-indicators') {
        $.get({
          url: '/epz/static/js/templates/analyticsStatisticsTemplates.htm',
          cache: true
        }
        ).then(function(templates) {
          let eisAllTmpl = $(templates).filter('#eis-all-tmpl').html();
          for (let i in searchResult) {
            let volume;
            if (searchResult[i]) {
              volume = searchResult[i].updateMap[`${Math.round(new Date().getTime() / 1000)}`];

              if (volume !== null && volume !== undefined) {
                volume = volume.toLocaleString('ru');
              }
              if (volume === null || volume === undefined) {
                volume = searchResult[i].prevVolume;
                if (volume !== null && volume !== undefined) {
                  volume = volume.toLocaleString('ru');
                }
              }
              if (volume === null || volume === undefined) {
                volume = 0;
              }
              let tmplData = {
                id: searchResult[i].key,
                caption: volume,
                desc: searchResult[i].volumeLabel,
                headerImg: iconForAllArr[i]
              };
              eisTabGrafics.append(Mustache.render(eisAllTmpl, tmplData));
            }
          }
          $('#eis-tab .spinner-block').addClass('d-none');
          thisReq.updateEisStatsIndicators();
        });
      } else {
        this.addGraficsSliderBtn();
        $.get({
          url: '/epz/static/js/templates/analyticsStatisticsTemplates.htm',
          cache: true
        }).then(
          function(templates) {
            let eisAllTmpl = $(templates).filter('#eis-all-tmpl').html();
            for (let i in searchResult) {
              if (searchResult[i]) {
                let volume = searchResult[i].volume ? searchResult[i].volume.toLocaleString('ru') : 0;
                let tmplData = {
                  id: searchResult[i].key,
                  caption: volume,
                  desc: searchResult[i].volumeLabel,
                  link: searchResult[i].url,
                  linkName: searchResult[i].urlLabel,
                  headerImg: iconForFzArr[i],
                  currencySymbol: searchResult[i].updatable
                };
                eisTabGrafics.append(Mustache.render(eisAllTmpl, tmplData));
              }
            }
            let customEvent = document.createEvent('HTMLEvents');
            customEvent.initEvent('eisSliderStatistics', true, true);
            document.dispatchEvent(customEvent);
            $('#eis-tab .spinner-block').addClass('d-none');
            thisReq.setTimerEisStatsFZ();
          });
      }
    });
  }

  addTen(digit) {
    return digit = digit < 10 ? '0' + digit : digit;
  }

  compareTmstmpByCurrently(tmstmp)
  {
    let currentDateDateTime = new Date();
    let receivedDateDateTime = new Date(tmstmp);
    return currentDateDateTime.getDate() === receivedDateDateTime.getDate() &&
      currentDateDateTime.getFullYear() === receivedDateDateTime.getFullYear() &&
      currentDateDateTime.getMonth() === receivedDateDateTime.getMonth();
  }

  addPeriod(updateDate) {
    let eisTabPeriod = $('#eis-tab .js-eis-tab__period');
    let minutes = new Date(updateDate).getMinutes();
    let hours = new Date(updateDate).getHours();
    let date = new Date(updateDate).getDate();
    let month = new Date(updateDate).getMonth() + 1;
    let year = new Date(updateDate).getFullYear();

    minutes = this.addTen(minutes);
    hours = this.addTen(hours);
    date = this.addTen(date);
    month = this.addTen(month);

    let time = `${hours}:${minutes} (МСК)`;
    let day = '';

    if (this.compareTmstmpByCurrently(updateDate)) {
      day = 'за сегодня';
    } else {
      day = `за ${date}.${month}.${year}`;
    }
    if (parameters.eisTab.tabType !== 'eis-indicators') {
      eisTabPeriod.html(`Информация <span class="highlight">${day},</span> по состоянию <span class="highlight">на ${time}</span>`);
    } else {
      eisTabPeriod.html(`Информация <span class="highlight">${day}`);
    }
  }

  addGraficsSliderBtn() {
    $('#eis-tab .js-grafics-btn').html(`
        <div class="grafics-btn-wrap grafics-btn_prev">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="24" cy="24" r="24" fill="#fff" stroke="#EFF0F2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4142
                      24.9999L24.7071 29.2928L23.2929 30.707L17.2929 24.707L16.5858
                      23.9999L17.2929 23.2928L23.2929 17.2928L24.7071 18.707L20.4142
                      22.9999L32 22.9999L32 24.9999L20.4142 24.9999Z" fill="#0065DD"/>
            </svg>
        </div>
        <div class="grafics-btn-wrap grafics-btn_next">
            <svg style="transform: rotate(180deg)" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="24" cy="24" r="24" fill="#fff" stroke="#EFF0F2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4142
                      24.9999L24.7071 29.2928L23.2929 30.707L17.2929 24.707L16.5858
                      23.9999L17.2929 23.2928L23.2929 17.2928L24.7071 18.707L20.4142
                      22.9999L32 22.9999L32 24.9999L20.4142 24.9999Z" fill="#0065DD"/>
            </svg>
        </div>
    `);
  }

  updateEisStatsIndicators() {
    this.intervalUpdateEisStats = setInterval(() => {
      if(this.isUpdate) {
        let isEmptyData = true;
        for (let i = 0; i < this.searchResult.length; i++) {
          if (this.searchResult[i]) {
            let element = document.getElementById(`${this.searchResult[i].key}`);
            let caption = element.querySelector('.js-eis-content__caption');
            let volume = this.searchResult[i].updateMap[`${Math.round(new Date().getTime() / 1000)}`];
            if (volume !== null && volume !== undefined) {
              isEmptyData = false;
              caption.textContent = volume.toLocaleString('ru');
            }
          }
        }
      }
    },1000);
  }
  updateEisStatsReq() {
    this.statisticsServices.getEisTabInfo().then((data) => {
      this.isUpdate = true;
      if(data.updateDateTime > this.updateDate) {
        this.searchResult = Object.keys(data.searchResult).map(e => data.searchResult[e]);
        this.updateDate = data.updateDateTime;
        this.addPeriod(data.updateDateTime);
        if(parameters.eisTab.tabType !== 'eis-indicators') {
          this.updateEisStatsFZ(true);
        }
        let timestump = Math.round(new Date().getTime() / 1000);
        for (let i = 0; i < this.searchResult.length; i++) {
          if (this.searchResult[i]) {
            if (this.searchResult[i].updateMap.hasOwnProperty(timestump)) {
              break;
            }
          }
        }
      } else {
        if(parameters.eisTab.tabType !== 'eis-indicators') {
          this.updateEisStatsFZ(false);
        }
      }
    });
  }

  setTimerEisStatsFZ() {
    clearTimeout(this.timerUpdateEisStats);
    let minute = new Date().getMinutes(), nextRefresh = (30 - (minute % 30)) * 60 * 1000;
    this.timerUpdateEisStats = setTimeout(this.updateEisStatsReq.bind(this), nextRefresh);
  }

  updateEisStatsFZ(update) {
    if (update) {
      for (let i = 0; i < this.searchResult.length; i++) {
        if (this.searchResult[i]) {
          let element = document.getElementById(`${this.searchResult[i].key}`);
          let link = this.searchResult[i].url;
          let volume = this.searchResult[i].volume;
          if (volume) {
            element.querySelector('.js-eis-content__caption').textContent = volume.toLocaleString('ru');
          }
          if (link) {
            element.querySelector('.js-eis-content__link').href = link;
          }
        }
      }
      this.setTimerEisStatsFZ();
    } else {
      this.updateEisStatsReq();
    }
  }
}

export default function() {
  new eisTab();
}
