const currentDate = new Date();
let defaultYearMap = currentDate.getMonth() + 1 < 4 ? `${currentDate.getFullYear()-1}`:`${currentDate.getFullYear()}`;
const roundingQuarter = currentDate.getFullYear().toString() === defaultYearMap && currentDate.getMonth() + 1 < 4 ? 1E6 : 1E9;
const nameLargeNumber = getNameLargeNumber(roundingQuarter);

let legendAttrs = {};

function setLegendAttrs(value) {
  legendAttrs = value;
}

let parameters = {
  commonTab: {
    placeOfSearch: 'FZ44',
    mode: 'map-stats',
    tabType: 'common-economy',
    year: defaultYearMap
  },
  purchaseTab: {
    placeOfSearch: 'FZ44',
    tabType: 'purchase-price',
    year: new Date().getFullYear(),
    kladr: '00000000000',
    contractStatus44FZ: 'ALL',
    contractStatus223FZ: 'ALL',
    mode: 'purchase-stats'
  },
  etpTab: {
    tabType: 'etp-price',
    year: new Date().getFullYear(),
    mode: 'etp-stats'
  },
  eisTab: {
    tabType: 'eis-indicators',
    mode: 'eis-stats'
  },
  common: {
    nameLargeNumber: nameLargeNumber
  }
};

function getTitleLegendMap() {
  let titleLegendMap = '';
  if(parameters.commonTab.placeOfSearch === 'FZ44') {
    switch (true) {
      case (parameters.commonTab.tabType === 'common-economy'):
        titleLegendMap = 'Относительная экономия по заключенным контрактам в разрезе субъектов РФ ';
        break;
      case (parameters.commonTab.tabType === 'common-planing'):
        titleLegendMap = 'Общая сумма размещенных планов-графиков в разрезе субъектов РФ ';
        break;
      case (parameters.commonTab.tabType === 'common-purchases'):
        titleLegendMap = 'Общая сумма размещенных извещений в разрезе субъектов РФ ';
        break;
      default:
        titleLegendMap = 'Общая сумма заключенных контрактов в разрезе субъектов РФ ';
        break;
    }
  }
  if(parameters.commonTab.placeOfSearch === 'FZ223') {
    switch (true) {
      case (parameters.commonTab.tabType === 'common-economy'):
        titleLegendMap = 'Относительная экономия по заключенным контрактам в разрезе субъектов РФ';
        break;
      case (parameters.commonTab.tabType === 'common-planing'):
        titleLegendMap = 'Общая сумма размещенных планов закупки в разрезе субъектов РФ';
        break;
      case (parameters.commonTab.tabType === 'common-purchases'):
        titleLegendMap = 'Общая сумма размещенных извещений в разрезе субъектов РФ ';
        break;
      default:
        titleLegendMap = 'Общая сумма заключенных договоров в разрезе субъектов РФ ';
        break;
    }
  }
  return titleLegendMap;
}

function getTitleLegendMapExplanation() {
  let explanation = '';
  if(parameters.commonTab.placeOfSearch === 'FZ44') {
    explanation = 'Регион определяется исходя из<br>информации об источнике<br>финансирования заказчика';
  }
  if(parameters.commonTab.placeOfSearch === 'FZ223') {
    explanation = 'Регион определяется исходя<br>из местоположения заказчика';
  }
  return explanation;
}

function getTitleLegendPurchase() {
  let titleLegend = '';
  if(parameters.purchaseTab.placeOfSearch === 'FZ44') {
    if (parameters.purchaseTab.tabType === 'purchase-price') {
      titleLegend = 'Цена заключенных контрактов по способам определения поставщика, ₽';
    } else {
      titleLegend = 'Количество заключенных контрактов по способам определения поставщика';
    }
  }
  if(parameters.purchaseTab.placeOfSearch === 'FZ223') {
    if (parameters.purchaseTab.tabType === 'purchase-price') {
      titleLegend = 'Цена заключенных договоров по способам определения поставщика, ₽';
    } else {
      titleLegend = 'Количество заключенных договоров по способам определения поставщика';
    }
  }
  return titleLegend;
}

function getLabelDialogLoaderPurchase() {
  let labelDialogLoaderPurchase = {};
  if(parameters.purchaseTab.placeOfSearch === 'FZ44') {
    if (parameters.purchaseTab.tabType === 'purchase-price') {
      labelDialogLoaderPurchase.labelLeft = 'Цена заключенных контрактов';
      labelDialogLoaderPurchase.labelRight = 'От общего объема контрактов по всем способам';
    } else {
      labelDialogLoaderPurchase.labelLeft = 'Количество заключенных контрактов';
      labelDialogLoaderPurchase.labelRight = 'От всех заключенных контрактов по всем способам';
    }
  }
  if(parameters.purchaseTab.placeOfSearch === 'FZ223') {
    if (parameters.purchaseTab.tabType === 'purchase-price') {
      labelDialogLoaderPurchase.labelLeft = 'Цена заключенных договоров';
      labelDialogLoaderPurchase.labelRight = 'От общего объема договоров по всем способам';
    } else {
      labelDialogLoaderPurchase.labelLeft = 'Количество заключенных договоров';
      labelDialogLoaderPurchase.labelRight = 'От всех заключенных договоров по всем способам';
    }
  }
  return labelDialogLoaderPurchase;
}

function getTitleLegendEtp() {
  let titleLegend = '';
  if(parameters.etpTab.tabType === 'etp-price') {
    titleLegend = 'Цена заключенных контрактов по 44‑ФЗ, ₽';
  } else {
    titleLegend = 'Экономия по заключенным контрактам 44‑ФЗ, %';
  }
  return titleLegend;
}

function getNameLargeNumber(value) {
  let nameLargeNumber = {
    labelFaceValue: '',
    rounding: 1
  };
  if(!value) value = 0;
  const strValueLength = value.toString().length;
  switch (true) {
    case (strValueLength >= 0 && strValueLength <= 6): {
      nameLargeNumber.labelFaceValue = 'тыс';
      nameLargeNumber.rounding = 1E3;
      break;
    }
    case (strValueLength > 6 && strValueLength <= 9): {
      nameLargeNumber.labelFaceValue = 'млн';
      nameLargeNumber.rounding = 1E6;
      break;
    }
    case (strValueLength > 9 && strValueLength <= 12): {
      nameLargeNumber.labelFaceValue = 'млрд';
      nameLargeNumber.rounding = 1E9;
      break;
    }
    default: {
      nameLargeNumber.labelFaceValue = 'трлн';
      nameLargeNumber.rounding = 1E12;
      break;
    }
  }
  return nameLargeNumber;
}

export {legendAttrs,
  parameters,
  defaultYearMap,
  getNameLargeNumber,
  getTitleLegendMap,
  getTitleLegendMapExplanation,
  getTitleLegendPurchase,
  getTitleLegendEtp,
  setLegendAttrs,
  getLabelDialogLoaderPurchase
};
