import {StatisticsServices} from '../services/statistics.services';
import {parameters, setLegendAttrs} from './utils/settings';
import commonTab from './commonTab';
import purchaseTab from './purchaseTab';
import etpTab from './etpTab';
import eisTab from './eisTab';
import {btnFloatingMode} from '../btnFloating';

export default function() {
  const isInitTab = {
    commonTab: true,
    purchaseTab: false,
    etpTab: false,
    eisTab: false
  };

  new StatisticsServices().getLegendAttrs().then((data) => {
    setLegendAttrs(data);
    commonTab();
    document.querySelector('.statistic-tabs [data-tab="#purchase-tab"]').addEventListener('click', (event) => {
      if (!isInitTab.purchaseTab) {
        isInitTab.purchaseTab = true;
        purchaseTab();
      }
    });
    let etpTabElem = document.querySelector('.statistic-tabs [data-tab="#etp-tab"]');
    if (etpTabElem) {
      etpTabElem.addEventListener('click', (event) => {
        if (!isInitTab.etpTab) {
          isInitTab.etpTab = true;
          etpTab();
        }
      });
    }
    document.querySelector('.statistic-tabs [data-tab="#eis-tab"]').addEventListener('click', (event) => {
      if (!isInitTab.eisTab) {
        isInitTab.eisTab = true;
        eisTab();
      }
    });

    const calculateMethodDoc = document.getElementById('calculate-method-doc');

    Array.prototype.forEach.call(document.querySelectorAll('.statistic-tabs .js-tab'), (el) => {
      el.addEventListener('click',(event) => {
        if (event.target.dataset.tab === '#common-tab' && parameters.commonTab.mode === 'map-stats') {
          calculateMethodDoc.classList.remove('d-none');
          return;
        }
        calculateMethodDoc.classList.add('d-none');
      });
    });
  });
}

