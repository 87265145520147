import 'slick-carousel';

export default function() {
  // //slider STATS
  const $slider = $('.eis-tab .js-grafics');
  const params = {
    dots: true,
    slidesToShow: 3,
    prevArrow: $('.eis-tab .grafics-btn_prev'),
    nextArrow: $('.eis-tab .grafics-btn_next'),
    autoplay: true,
    autoplaySpeed: 10000
  };
  $slider.slick(params);

  $('.statistic .statistic-tabs .js-tab ').click(function() {
    if(this.dataset.tab === '#eis-tab') {
      $slider.slick('slickPlay');
    } else {
      $slider.slick('slickPause');
    }
  });
}
