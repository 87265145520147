import 'slick-carousel';
import {parameters} from './utils/settings';
export default function() {
  // //slider STATS
  const $slider = $('#common-tab .grafics-slider');
  var count = 0;
  var initCountOffset = (parameters.commonTab.tabType === 'common-planing' &&
    (parameters.commonTab.placeOfSearch === 'FZ44' || parameters.commonTab.placeOfSearch === 'FZ223')) ? 6 : 4;
  count = $('#common-tab .card').length - initCountOffset;
  const params = {
    slidesToShow: 4,
    initialSlide: count,
    prevArrow: $('#common-tab .grafics-btn_prev'),
    nextArrow: $('#common-tab .grafics-btn_next'),
    infinite: false
  };
  const arrows = {
    active: 'img/icons/slider-btn-active.svg',
    nonactive: 'img/icons/slider-btn.svg',
  };


  $slider.slick(params);
  $slider.on('afterChange', (e, slick, currentSlide) => {
    if (JSON.parse($('#common-tab .grafics-btn_prev').attr('aria-disabled'))) {
      // $('.grafics-btn_prev').find('.img-arrow').attr('src', arrows.nonactive);
      $('#common-tab .grafics-btn_prev path').attr('fill','#909EBB');
    } else {
      // $('.grafics-btn_prev').find('.img-arrow').attr('src', arrows.active);
      $('#common-tab .grafics-btn_prev path').attr('fill','#0065DD');
    }

    if (JSON.parse($('#common-tab .grafics-btn_next').attr('aria-disabled'))) {
      // $('.grafics-btn_next').find('.img-arrow').attr('src', arrows.nonactive);
      $('#common-tab .grafics-btn_next path').attr('fill','#909EBB');
    } else {
      // $('.grafics-btn_next').find('.img-arrow').attr('src', arrows.active);
      $('#common-tab .grafics-btn_next path').attr('fill','#0065DD');
    }
  });
}
